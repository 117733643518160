@use './assets/style/colors' as color;
@import url("./pages/hb/hb.scss");
@import url("./pages/pshb/pshb.scss");

 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.b-radius-20{
  border-radius: 20px !important;
}

.table > :not(:first-child) {
  border-top: 0 !important;
}

.relative
{
  position: relative;
}
.modal-content{
  border-radius: 1rem !important;
}

.text-danger-white
{
  color:white;
}

.nav-link
{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.perfil .selected-text
{
  padding-left: 0px !important;
}

.iconoNovedades{
  height: 16px;
  margin-left: 3px;
  margin-bottom: 3px;
}

.custom-input-login{
  border:0;outline:0;    
  color:#224d7c;    
  position: absolute;
}

custom-input-login:focus {
  outline:none!important;
}

.custom-input-login::placeholder {
  font-weight: bold;
  opacity: .7;
  color: color.$color-HB-blue-strong;           
}

.p-0{
  padding: 0px !important;
}

.input-login{
  background: transparent !important;
}

.custom-input-login{
  background: transparent !important;
}

::-ms-reveal {
  display: none
}