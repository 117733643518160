.table-hb tbody tr {
  font-family: "Roboto-Regular";
  font-size: 20px;
  color: #05294f;
  //text-align: justify;
}

.table-hb thead tr {
  font-family: "Roboto-Regular";
  font-size: 25px;
  color: #05294f;
  //text-align: justify;
}

.btn-hb {
  &-primary {
    color: #fff !important;
    background-color: #05294f !important;
    border-radius: 20px !important;
    padding: 1px 20px 1px 20px !important;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    font-family: "Roboto-Bold";
    font-size: 35px;
    //border:2px solid #0367C3 !important;
    &:hover {
      background-color: white !important;
      color: #033261 !important;
      border: 1px solid #033261 !important ;
    }
  }

  &-primary-without-border {
    color: #fff !important;
    background-color: #05294f !important;
    border-radius: 20px !important;
    padding: 1px 20px 1px 20px !important;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    font-family: "Roboto-Medium";
    font-size: 28px;
    border: 1px solid #0367c3 !important;
    &:hover {
      background-color: white !important;
      color: #033261 !important;
    }
  }

  &-secondary-without-border {
    color: #033261 !important;
    background-color: #fff !important;
    border: 1px solid #033261 !important;
    border-radius: 20px !important;
    padding: 1px 20px 1px 20px !important;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    height: 30px;
    font-family: "Roboto-Medium";
    &:hover {
      color: #fff !important;
      background-color: #05294f !important;
    }
  }
}

.footer-hb {
  background-image: url("../../assets/img/hb/Backgrounds/bg-red-2.png");
  height: 105px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.footer-hb-large {
  background-image: url("../../assets/img/hb/Backgrounds/FooterRojoLarge.png");
  height: 215px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.footer-hb-small {
  background-image: url("../../assets/img/hb/Backgrounds/FooterRojoSmall.svg");
  height: 69px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  margin-top: 20px;
}

.footer-hb-2 {
  background-image: url("../../assets/img/hb/footer-hb-2.png");
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
}
.footer-hb-3 {
  background-image: url("../../assets/img/hb/Backgrounds/bg-red-3.png");
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

// .footer-ps{
//   background-image: url("../../assets/img/pshb/Backgrounds/bg-green.png");
//   height: 100px;
//   width: 100%;
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position-y: bottom;

// }

.number-hb-paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto-Bold";
}

.number-hb-paginate a {
  color: #05294f;
}

.separador-table-red-result {
  border-top: 2px solid red;
  margin: 10px 0px;
}

.section_hb_title {
  color: #05294f;
  font-family: "Roboto-Medium";
  font-size: 30px;
}

.section_hb_subtitle {
  color: #05294f;
  font-family: "Roboto-Regular";
  font-size: 16px;
  padding-top: 10px;
}
