.header-proteccion {
  border-bottom: 0px !important;
  padding-right: 35px !important;
  padding: 0px !important;
  padding-left: 20px !important;
  padding-right: 30px !important;
  padding-top: 5px !important;
}

.body-proteccion {
  padding: 0px !important;
}

.titulo-facturas {
  color: #05294f;
  padding-left: 20px;
  padding-top: 60px;
  font-family: "Roboto-Medium";
  font-weight: normal !important;
  font-size: 25px;
  // font-weight: 700;
}

.imagen-multimedia {
  width: 100%;
  height: 50%;
  padding-top: 1em;
  padding-bottom: 1em;
}

// .politica-proteccion{
//     color:#05294F;
//     padding: 0px 20px;

// }

// .politica-proteccion p{
//     text-align: justify;
//     font-family: Roboto-Regular;

// }

// .politica-proteccion h5{
//     font-family: Roboto-Regular;
//     color: #004990;
//     font-weight: 600;
// }

.barra-informacion {
  height: 3px;
  background: #e0e9f2;
  opacity: 1;
  border-radius: 10px;
  margin-bottom: 20px;
}

// .btn-close{
//     padding-top: 0px!important;
// }

.section-information {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.image-information {
  padding-right: 10px;
}

.section-consult {
  margin-left: 10px;
}

.answer {
  font-family: "Roboto-Regular";
  color: #05294f;
  font-weight: 600;
}

.question {
  font-family: "Roboto-Medium";
  color: #05294f;
  font-weight: 600;
}

.header-familygroup-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 0px;
}

.header-familygroup-information p {
  margin-left: 20px;
  margin-right: 20px;
}

// .btn-close
// {
//     align-self: baseline;
// }

.custom-header {
  border-bottom: 0px;
}

.pl-20 {
  margin-left: 20px;
}

.circulo {
  // -moz-border-radius: 50%;
  // -webkit-border-radius: 50%;
  width: 25px !important;
  height: 25px;
  border-radius: 50%;
  border: solid 2px red;
  color: red;
  text-align: center;
  font-family: "Roboto-Bold" !important;
  margin-bottom: 15px;
  padding-top: 1px !important;
}

.mb-70 {
  margin-bottom: 40px;
}
