$blueColor: rgb(3, 50, 97);
$redColor: rgb(255, 23, 23);
$font: Asisstant-Bold;

#headerContent {
  display: flex;
  justify-content: space-between;
}

#sideRight {
  display: flex;
  align-items: center;
}

#title-study-results {
  max-width: 210px;
  width: 100%;
  height: 100%;
  margin-right: 5px;
  font-family: "Roboto-Medium";
  color: #05294f;
  display: flex;
  align-items: center;
  //font-family: $font;
}

// .shadow-blue {
//     -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
//     box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
// }

// .shadow-red {
//     -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
//     box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
// }

#user {
  width: 100vw;
  max-width: 300px;
  background-color: $blueColor;
}

#user option {
  background-color: $blueColor;
}

.section_hb_title {
  font-weight: normal !important;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field-results {
  padding: 2px 35px;
  border-radius: 20px;
  border: 2px solid transparent;
  background-color: $blueColor;
  color: white;
  font-family: "Roboto-Regular";
  margin-top: 1px;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: "Roboto-Regular";
    text-align: justify;
  }
}

.input-field-results:focus {
  outline: none;
}

.i-25-result {
  margin-right: 15px;
}

.pointer {
  cursor: pointer;
  margin-right: 15px;
}

.custom-icon-results {
  transform: translate(100%, 50%);
  color: #00c1e3;
  position: absolute;
}

#estudio {
  background-color: $redColor;
  border-color: $redColor;
  color: white;
}

#findUserName {
  display: flex;
}

#filterServices {
  max-width: 400px;
  width: 100%;
}

.separador-result {
  border-top: 3px solid rgb(171 195 219);
  margin-bottom: 10px;
  margin-top: 10px;
}

.col-years-results {
  display: flex;
  justify-content: end;
  // align-items: center;
  //padding-bottom: 10px;
}

#paginate {
  display: flex;
  justify-content: end;
}

#dropdown-names {
  align-items: center;
  margin-bottom: 10px;
}

#tableResults {
  color: #033261;
}

.container-label-results {
  margin-top: 3rem;
}

.options-results {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 12px !important;
}

@media (max-width: 992px) {
  #tableResults td {
    font-size: 14px;
  }

  #findUserName {
    // margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .separador-table-red-result {
    margin-top: 0px !important;
  }

  #filterServices {
    margin-top: 4px;
  }

  .container-label-results {
    margin-top: 0px;
  }

  .title-results {
    margin-bottom: 15px;
  }

  .input-icons {
    width: 100%;
  }

  .input-field-results {
    width: 100%;
  }

  #filterServices {
    position: relative;
  }

  #filterServices .custom-select-container-red {
    width: 100% !important;
  }
}

section {
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  #container-resultados {
    // min-height: 75vh;
  }

  #dropdown-name {
    // padding-top: 1rem !important;
  }

  .año-estudio {
    padding-top: 5px !important;
  }
}

.resultado-etiqueta {
  font-family: "Roboto-Medium";
  font-size: 14px;
  color: rgb(3, 50, 97);
  margin-right: 5px;
}

.resultado-valor {
  font-family: "Roboto-Regular";
  color: rgb(3, 50, 97);
  font-size: 14px;
}

.iconos-acciones-tarjeta {
  display: flex;
  justify-content: end;
  gap: 15px;
}

.iconos-acciones-tarjeta img {
  cursor: pointer;
}

.btn-red-without-border {
  color: #fff !important;
  background-color: red !important;
  border-radius: 20px !important;
  padding: 1px 20px 1px 20px !important;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  font-family: "Roboto-Medium";
  font-size: 14px;
  border: 1px solid red !important;
  line-height: 24px;
}

// AJUSTE RESPONSIVE PARA DROPDOWNS
@media screen and (min-width: 1250px) {
  .item-1 {
    width: 50%;
  }

  .item-2 {
    width: 25%;
    justify-content: end;
  }

  .item-3 {
    width: 25%;
  }

  .item-3 div {
    max-width: inherit !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1250px) {
  .item-1 {
    width: 60%;
  }

  .item-2 {
    width: 40%;
    justify-content: start;
  }

  .item-3 {
    width: 290px;
  }

  #separator-top-results {
    margin-top: 50px !important;
  }

  .item-3 div {
    max-width: inherit !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .item-1 {
    width: 100%;
  }

  .item-2 {
    width: 100%;
  }

  .item-3 {
    width: 100%;
    margin-top: 20px;
  }

  #separator-top-results {
    margin-top: 50px !important;
  }

  .item-3 div {
    max-width: inherit !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .item-2 {
    width: 100%;
    margin-top: 50px;
  }

  .item-3 {
    width: 100%;
    margin-top: 15px;
  }

  .item-3 div {
    max-width: inherit !important;
  }

  #separator-top-results {
    margin-top: 10px !important;
  }

  .separador-table-red-result {
    margin-top: 35px !important;
  }

  .separador-table-red-result2 {
    margin-top: 0px !important;
  }

  #filterServices {
    max-width: 100% !important;
  }
}

//FIN AJUSTES RESPONSIVE PARA DROPDOWNS
