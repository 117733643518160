#title-logout-HB {
  font-family: Roboto-Light;
}

.footer-hb-logout {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABzMAAABYBAMAAACDh8cDAAAAGFBMVEUAAAAASZAFKU87h9LW6//29vb/AAD///9N5FepAAAAAXRSTlMAQObYZgAAAAFiS0dEBxZhiOsAAAH3SURBVBgZ7cFBTlNhAIXRr9dfx23cAOkW2IADtsDcEYtixBLcSBNX0LCDOsYoJmhiTDWk9sG70O+cBZLIGUW2QJBUKEgqFCQVCpIKBUmFgqRCQVKhIKlQkFQoSCoUJBUaSJrM+MA07oKkQkFSoSCpUJBUKEgqNJAOc8UvdzfoyQRJhYKkQgPN5Yp910gPgqRCQVKhgV6HNUf6fouKBEmFgqRCA6nJYsksduxZLJlRkFQoSCoUJBUKkgoFSYUGk1ks0d/df0E6TJBUaKBTt+Kn9/y2QTMLkgoFSYUG0mGuOQHn/KctR/h+y4MtECQVGkhN7nfMIWeUCZIKBUmFBprQmiOdc6Qdeh2CpEJBUqGB9PwWS8rsKBMkFRqclBXT2SE9nSCpUJBUKEgqFCQVGugIl+zZIE0gSCoUJBUaaC4bpH8KkgoNJnO/o96K6ezQ67XhUIslkwqSCgVJhYKkQkFSoSCpUJBUKEgqNC44JVumc0GjNQfbse+C+X37zEvy5pxHbDhEkFQoSCoUJBUa6NSt6PT2kufy9RN1gqRCQVKhgaRnseUQQVKhgXTyxgV1gqRCA+lxOUPPK0gqFCQVWnBS1kxnC1yxZ8OsNjyJdx95ze5uqDPQEa750zl6iW7oM9CENkjTGJyULdLLECQVCpIKBUmFgqRCPwBdhCyUuF57KwAAAABJRU5ErkJggg==);
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  position: absolute;
  bottom: 0;
}
.logout {
  z-index: 1000;
  // border: 1px solid red;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  height: 100vh;
}
