#titulo-error {
  font-size: 40px;
  color: #004890;
}

#subTitulo-error {
  font-size: 30px;
  color: #004890;
}

#page-error-pshb #titulo-error {
  font-family: "Roboto-Bold";
}

#page-error-pshb #subTitulo-error {
  font-family: "Roboto-Regular";
}

#page-error-hb #titulo-error {
  font-family: "Roboto-Bold";
}

#page-error-hb #subTitulo-error {
  font-family: "Roboto-Regular";
}
