.header-proteccion {
  border-bottom: 0px !important;
  padding-right: 35px !important;
  padding: 0px !important;
  padding-left: 20px !important;
  padding-right: 30px !important;
  padding-top: 5px !important;
}

.body-proteccion {
  padding: 0px !important;
}

.titulo-proteccion {
  color: #004990;
  padding-left: 20px;
  padding-top: 60px;
  font-family: Roboto-Regular;
  font-weight: bolder;
  font-size: 40px;
  font-weight: 700;
}
.titulo-proteccion-pshb {
  color: #004990;
  padding-left: 20px;
  padding-top: 60px;
  font-family: Roboto-Regular;
  font-weight: bolder;
  font-size: 40px;
  font-weight: 700;
}

.politica-proteccion {
  color: #05294f;
  padding: 0px 20px;
}
.politica-proteccion-pshb {
  color: #05294f;
  padding: 0px 20px;
}

.politica-proteccion p {
  text-align: justify;
  font-family: Roboto-Regular;
}
.politica-proteccion-pshb p {
  text-align: justify;
  font-family: Objectivity-Regular;
}

.politica-proteccion h5 {
  font-family: Roboto-Regular;
  color: #004990;
  font-weight: 600;
}

.politica-proteccion-pshb h5 {
  font-family: Roboto-Regular;
  color: #004990;
  font-weight: 600;
}

.barra-politica-proteccion {
  height: 6px;
  background: #ff0000;
  opacity: 1;
  border-radius: 10px;
  margin-bottom: 20px;
}

.barra-politica-proteccion-pshb {
  height: 6px;
  background: #99ca3e;
  opacity: 1;
  border-radius: 10px;
  margin-bottom: 20px;
}
