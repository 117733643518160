.text-center-mobile {
  display: none;
  color: #004990;
  font-family: "Roboto-Regular";
  font-size: 15px;
}

.text-center {
  font-family: "Roboto-Regular";
  font-size: 16px;
}

.contenedorBanner {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.imagenFondo {
  width: 100%;
  height: 100%;
  // min-height: 70vh;
  max-height: 70vh;
  object-fit: cover;
}

.novedad-desktop {
  display: block;
}
.novedad-mobile {
  display: none;
}

@media (max-width: 992px) {
  .imagenFondo {
    height: 470px;
    max-height: 470px;
    min-height: 470px;
  }

  .atencion {
    font-size: 18px !important;
  }

  .novedad-desktop {
    display: none;
  }
  .novedad-mobile {
    display: block;
  }
}

.bienvenidaHB {
  position: absolute;
  left: 10%;
  bottom: 35%;
  color: #fff;
  /* font-size: 3rem; */
  font-weight: bold;
  line-height: 100%;
}

.planSalud {
  position: absolute;
  top: 0px;
  width: 100%;
  background: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0px;
}

.planSalud .img-fluid {
  height: 2.5em;
}

.planSalud span {
  color: #05294f;
  cursor: pointer;
  font-family: Roboto-Regular;
}

.spanRed {
  color: red !important;
  font-weight: bold;
}

.atencion {
  min-height: 70px;
  background: #fff;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
  padding: 0px 20px 5px 20px;
}
.atencionps {
  min-height: 70px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  padding: 0px 20px 5px 20px;
}

.iconosAtencion {
  width: 1rem;
}

.contenedorNovedades {
  position: absolute;
  right: 0px;
  top: 45%;
  color: #fff;
}

#novedadestext {
  color: white;
  font-size: small;
  font-family: Roboto-Light;
}

.novedadesCab {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  // background: rgb(125, 0, 0);
  // background: linear-gradient(0deg, rgba(125, 0, 0, 1) 0%, rgba(156, 0, 0, 1) 19%, rgba(200, 0, 0, 1) 40%, rgba(226, 0, 0, 1) 56%, rgba(255, 0, 0, 1) 70%, rgba(255, 75, 75, 1) 87%, rgba(255, 123, 123, 1) 100%);
  background: rgb(125, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(125, 0, 0, 1) 0%,
    rgba(200, 0, 0, 1) 50%,
    rgba(255, 123, 123, 1) 100%
  );
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.novedadesDet {
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  // background: rgb(125, 0, 0);
  // background: linear-gradient(0deg, rgba(125, 0, 0, 1) 0%, rgba(156, 0, 0, 1) 19%, rgba(200, 0, 0, 1) 40%, rgba(226, 0, 0, 1) 56%, rgba(255, 0, 0, 1) 70%, rgba(255, 75, 75, 1) 87%, rgba(255, 123, 123, 1) 100%);
  background: rgb(125, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(125, 0, 0, 1) 0%,
    rgba(200, 0, 0, 1) 50%,
    rgba(255, 123, 123, 1) 100%
  );
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bienvenidaHB-pc {
  font-family: Roboto-Bold;
}

@media (max-width: 991.98px) {
  .bienvenidaHB-movil {
    position: absolute;
    left: 10%;
    top: 80px;
    color: #fff;
    /* font-size: 3rem; */
    font-weight: bold;
    line-height: 100%;
    height: 100px;
    text-shadow: 0 0 5px #bababc, 0 0 5px #bababc, 0 0 5px #bababc,
      0 0 5px #bababc, 0 0 5px #bababc;
  }

  .contenedorNovedades-movil {
    position: absolute;
    right: 0px;
    top: 110px;
    color: #fff;
  }

  .text-center-mobile {
    display: block;
  }

  .planSalud span {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .bienvenidaHB-pc {
    position: absolute;
    left: 10%;
    bottom: 35%;
    color: #fff;
    font-size: 3.5rem !important;
    font-weight: bold;
    line-height: 100%;
  }

  .contenedorNovedades-pc {
    position: absolute;
    right: 0px;
    top: 45%;
    color: #fff;
  }
}

/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contenedorGrupoMenu-lg {
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
}

/* xl */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .contenedorGrupoMenu-xl {
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
}

/* xxl */
@media (min-width: 1400px) {
  .contenedorGrupoMenu-xxl {
    position: absolute;
    bottom: 40px;
    width: 100%;
  }
}
