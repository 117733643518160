.drawer {
  background: linear-gradient(
    0deg,
    #05294f 20%,
    #02366b 40%,
    #034f9a 60%,
    #0062c1 80%,
    #3cceeb 100%
  );
}

.drawer-name {
  padding: 0px 15px 0px 15px;
}

.asideWebHBMobile {
  padding-top: 30px;
  background-color: #efefef;
}

.menuMobile {
  padding-top: 0 !important;
}

.perfil-bottom {
  padding-bottom: 10px !important;
}
