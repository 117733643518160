.iframe {
  width: 100%;
  height: 100%;
  min-height: 70vh;
  border: none;
}

.spiner-container {
  position: fixed;
}

.hide-iframe {
  visibility: hidden;
}
