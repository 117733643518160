.datosCobertura {
  padding: 40px 0px 0px 0px;
  width: 100%;
  display: flex;
  align-content: space-between;
}

.margin-side {
  margin-left: 6%;
  margin-right: 6%;
}

.seccion-cobertura {
  width: 84%;
}

.seccion-cobertura-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: last baseline;
}

@media screen and (max-width: 1000px) {
  .seccion-cobertura {
    width: 100%;
  }
}

.tarjeta {
  //Inactiva
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgb(189 216 239);
  border-left: 20px solid rgb(255 0 0);
  padding: 20px;
  margin: 20px;
  box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
  -webkit-box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
  -moz-box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
  font-family: Assistant-Medium;
  padding-bottom: 28px;
  padding-top: 28px;

  //Inactiva PSHB
  &--pshb {
    font-family: Objectivity-Medium;
    border-left: 20px solid #8acb48;
  }

  // Activa de HB Y PSHB
  &--activa {
    background-color: #fff;
    border-radius: 10px;
    border-left: 20px solid rgb(255, 0, 0);
    padding: 20px;
    margin: 20px;
  }
}

.tituloActivo {
  // font-weight: bold;
  // color: #fff;
  color: rgb(3 50 97);
  // font-size: xx-large;
}

.tituloInactivo {
  // font-weight: bold;
  color: rgb(3 50 97);
  // font-size: xx-large;
}

.formActivo {
  display: flex;
  flex-direction: column;
  color: rgb(3 50 97);
}

.formInactivo {
  display: flex;
  flex-direction: column;
  color: rgb(3 50 97);
}

.campo {
  margin: 5px;
  padding: 5px;
}

.campo span {
  margin-right: 5px;
  font-weight: 700;
}

.editandoCampo {
  background-color: #034787;
}

.botonEditar {
  background-color: rgb(138 203 72);
  border: 1px solid rgb(138 203 72);
  width: 8em;
  border-radius: 25px;
  color: #fff;
  margin-right: 10px;
}

.botonCancelar {
  background-color: rgb(255 0 0);
  border: 1px solid rgb(255 0 0);
  width: 8em;
  border-radius: 25px;
  color: #fff;
}

.lineaActiva {
  border-top: 1px solid rgb(55 92 140);
  width: 95%;
  margin: 0 auto;
}

.lineaInactiva {
  border-top: 1px solid #a3b3cf;
  width: 95%;
  margin: 0 auto;
}

.lineaCabActiva {
  border-top: 3px solid rgb(55 92 140);
  margin: 30px 0px;
}

.lineaCabInactiva {
  border-top: 3px solid rgb(55 92 140);
  margin: 30px 0px;
}

.lineaCabInactivaPSHB {
  border-top: 3px solid rgb(138 203 72);
  margin: 30px 0px;
}

.botones {
  margin: 10px;
}

.userHB .perfil-etiqueta {
  font-family: "Roboto-Medium";
  font-weight: inherit !important;
  font-size: 18px;
  // width: max-content;
}

.userHB .perfil-valor {
  font-family: "Roboto-Regular";
  font-weight: inherit !important;
  font-size: 18px;
}

.userHB .tituloActivo {
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.userHB .tituloInactivo {
  margin-bottom: 0px !important;
}

.userPSHB .perfil-etiqueta {
  font-family: "Objectivity-Regular" !important;
  font-weight: inherit !important;
  font-size: 18px;
}

.userPSHB .perfil-valor {
  font-family: "Objectivity-Regular" !important;
  font-weight: inherit !important;
  font-size: 20px;
}

.userPSHB .tituloActivo {
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.userPSHB .tituloInactivo {
  margin-bottom: 0px !important;
}

@media (max-width: 1150px) {
  .userHB .perfil-valor,
  .userPSHB .perfil-valor {
    font-size: 16px;
  }

  .userHB .perfil-etiqueta,
  .userPSHB .perfil-etiqueta {
    font-size: 16px;
  }
}

// @media (max-width:992px) {
//     .userHB .perfil-valor, .userPSHB .perfil-valor{
//         font-size: 18px;
//     }

// }
