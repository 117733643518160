.pointer {
  cursor: pointer;
}

.i-25-at-cert {
  width: 25px !important;
  margin-left: 1em;
}

.seccion-certificados {
  min-height: 100%;
}

@media only screen and (max-width: 1000px) {
  .table-hb thead tr {
    text-align: inherit;
    font-size: 20px;
    line-height: normal;
  }

  .table-hb tbody tr td {
    text-align: inherit;
    font-size: 14px;
    line-height: normal;
  }
  .i-25-at-cert {
    margin-bottom: 1em;
  }
}

.search-patiente-asistencia {
  margin-bottom: 45px;
}

@media only screen and (max-width: 768px) {
  .search-patiente-asistencia {
    margin-bottom: 20px;
  }
}
