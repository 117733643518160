.card-refund {
  padding: 25px;
}

.section-date {
  display: flex;
  flex-direction: column;
}

.sections-date {
  display: flex;
  flex-direction: row;
}

.section-search-and-print {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-icons-pshb i {
  position: absolute;
}

.input-icons-pshb {
  margin-bottom: 10px;
}

.input-icons-pshb .icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field-results-pshb {
  padding: 2px 35px;
  border-radius: 20px;
  border: 2px solid transparent;
  outline: none;
  background-color: white;
  color: #05294f;
  font-family: "Roboto-Regular";
  margin-top: 1px;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #05294f;
    opacity: 1; /* Firefox */
    font-family: "Roboto-Regular";
  }
}

.custom-icon-results-pshb {
  transform: translate(100%, 50%);
  color: #8acb48;
  position: absolute;
}

.input-icons-date-pshb i {
  position: absolute;
}

.input-icons-date-pshb-refund {
  margin-bottom: 0px !important;
  display: flex;
  justify-content: end;
}

.h-30 {
  height: 30px;
}

.input-icons-date-pshb .icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field-results-date-pshb {
  padding: 2px 35px;
  border-radius: 20px;
  border: 2px solid transparent;
  outline: none;
  background-color: white;
  color: #05294f;
  font-family: "Roboto-Regular";
  margin-top: 1px;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #05294f;
    opacity: 1; /* Firefox */
    font-family: "Roboto-Regular";
  }
}

.custom-icon-results-date-pshb {
  transform: translate(-120%, 40%);
  color: #05294f;
  fill: #05294f;
  position: absolute;
}

input[type="date"]:focus {
  color: #05294f;
}

// input[type="date"]::-webkit-inner-spin-button,
// input[type="date"]::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
// }

input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}

@media (max-width: 768px) {
  .btn-search {
    margin-top: 0px !important;
  }
}

.input-date-ios {
  -webkit-appearance: none;
}
