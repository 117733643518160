@use "../../../assets/style/colors" as color;

/* IMAGE: RIGHT SIDE */
.bg-image-pshb {
  // background-image: url("../../../assets/img/hb/test2.jpg");
  background-color: #cccccc;
  height: 100vh;
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 50vw;
}
.bg-image {
  // background-image: url("../../../assets/img/hb/image-login.jpg");
  background-color: #cccccc;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 50vw;
}

.bg-image-text {
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
}

.transbox {
  /* margin: 30px; */
  background-image: linear-gradient(
    0deg,
    rgba(57, 93, 132, 0.5),
    rgba(2, 10, 20, 0.5)
  );
  /* background: rgba(57,93,132,.5); */
  /* color: white; */
  padding: 4%;
}

.default-fontColor {
  color: color.$color-HB-blue-principal;
}

/* LOGIN: LEFT SIDE */
.div-login {
  width: 50vw;
}
.img-logo {
  width: 10vw;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid color.$color-HB-blue-principal;
  margin: 1em 0;
  padding: 0;
  min-width: 10.5vw;
}

.input-login {
  border-radius: 20px;
  width: 50%;
  border: 1px solid color.$color-HB-blue-light;
  min-height: 4.5vh;
  color: color.$color-HB-blue-strong;
  padding-left: 2vh;
  padding-right: 2vh;
}

.shadow-blue {
  -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.input-login::placeholder {
  font-weight: bold;
  opacity: 0.7;
  color: color.$color-HB-blue-strong;
}

.input-login:focus {
  outline: none !important;
  border-color: color.$color-HB-blue-strong;
  color: color.$color-HB-blue-light;
  padding-left: 2vh;
}

.dot {
  margin: 10px;
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid color.$color-HB-blue-principal;
}

.br {
  margin: 1vh;
}

.button-div-facebook {
  border: 1px solid color.$color-HB-blue-light;
  width: 200px;
  border-radius: 20px;
  // min-height: 4.5vh;
  display: flex;
  align-items: center;
}

.button-div-facebook i {
  padding-left: 1vh;
}

.button-div-facebook button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 99%;
  color: color.$color-HB-blue-strong;
  font-weight: 500;
}

.button-div-facebook:hover {
  font-weight: 500;
  background: #cee3f7;
}

.button-div {
  border: 1px solid color.$color-HB-blue-light;
  width: 50%;
  border-radius: 20px;
  min-height: 4.5vh;
  display: flex;
  align-items: center;
}

.button-div i {
  padding-left: 2vh;
}

.button-div button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 99%;
  color: color.$color-HB-blue-strong;
  font-weight: 500;
}

.button-div:hover {
  font-weight: 500;
  background: #cee3f7;
}

.link-noStyle {
  /* background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    color: color.$color-HB-blue-strong;; */
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  color: color.$color-HB-blue-strong;
}

.link-noStyle:hover {
  text-decoration: none;
  color: color.$color-HB-blue-strong;
  font-weight: 500;
}

.button-loader {
  background: color.$color-HB-blue-strong;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: white;
  width: 25%;
  border-radius: 20px;
  border: 1px solid color.$color-HB-blue-light;
  min-height: 4.5vh;
  font-weight: bold;
}

.button-loader-white {
  background: white;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: color.$color-HB-blue-strong;
  width: 25%;
  border-radius: 20px;
  border: 1px solid color.$color-HB-blue-light;
  min-height: 4.5vh;
  font-weight: bold;
}

.button-continuar {
  background: color.$color-HB-blue-strong;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: white;
  width: 25%;
  border-radius: 20px;
  border: 1px solid color.$color-HB-blue-light;
  min-height: 4.5vh;
  font-weight: normal;
  font-family: Roboto-Regular;
}

.button-continuar:hover {
  background: white;
  color: color.$color-HB-blue-strong;
}

@media only screen and (max-width: 1200px) {
  .button-div {
    min-height: 6vh;
  }

  .input-login {
    min-height: 6vh;
  }

  .input-login {
    width: 80%;
  }

  hr {
    min-width: 17vw;
  }

  .hr-box {
    width: 79%;
  }

  .button-div {
    width: 80%;
  }

  .img-logo {
    width: 15vw;
  }

  .button-continuar {
    width: 50%;
    min-height: 6vh;
  }

  .button-loader {
    width: 50%;
    min-height: 6vh;
  }
}

@media only screen and (max-width: 800px) {
  .button-div {
    min-height: 6vh;
  }

  .input-login {
    min-height: 6vh;
  }

  .div-login {
    width: 100vw;
  }

  .bg-image {
    display: none;
  }

  .input-login {
    width: 80%;
  }

  .hr-box {
    width: "80%";
  }

  hr {
    min-width: 34.5vw;
  }

  .hr-box {
    width: 79%;
  }

  .button-div {
    width: 80%;
  }

  .img-logo {
    width: 30vw;
  }

  .button-continuar {
    width: 50%;
    min-height: 6vh;
  }

  .button-loader {
    width: 50%;
    min-height: 6vh;
  }
}
