#tablePrescriptions th:nth-child(3),
#tablePrescriptions td:nth-child(3) {
  width: 20%;
}

#tablePrescriptions th:nth-child(5),
#tablePrescriptions td:nth-child(5) {
  width: 20%;
}

#tablePrescriptions th:nth-child(6),
#tablePrescriptions td:nth-child(6) {
  text-align: center;
}

#container-prescripcions {
  padding-right: 30px;
  padding-left: 30px;
}

.mouse-pointer {
  cursor: pointer;
}