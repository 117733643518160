$blueColor: rgb(3, 50, 97);
$redColor: rgb(255, 23, 23);

@media (max-width: 600px) {
  .table-mobile {
    padding-left: 10px;
    padding-right: 10px;
  }

  .claseMobile {
    margin-left: 0 !important;
    display: flex;
    justify-content: start;
    align-items: center;
    width: inherit;
    gap: 5px;
  }

  .section-mobile {
    display: grid !important;
  }

  .input-debito {
    width: inherit !important;
  }

  .boton-confirmar-mobile {
    justify-self: center;
  }
}

#headerContent {
  display: flex;
  justify-content: space-between;
}

.email-cambiar {
  font-family: "Roboto-Regular" !important;
}

.modalBody {
  padding-left: 30px;
  padding-right: 30px;
}

.enviarDiv {
  margin: 0;
}

#sideRight {
  display: flex;
  align-items: center;
}

#titleStudy {
  max-width: 200px;
  width: 100%;
  margin-right: 15px;
  font-family: "Roboto-Medium";
}

// .shadow-blue {
//     -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
//     box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
// }

// .shadow-red {
//     -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
//     box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
// }

#user {
  width: 100vw;
  max-width: 300px;
  background-color: $blueColor;
}

#user option {
  background-color: $blueColor;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field {
  padding: 2px 35px;
  border-radius: 20px;
  border: 1px solid #224d7c;
  background-color: $blueColor;
  color: white;
  font-family: "Roboto-Regular";
}

.input-field:focus {
  outline: none;
}

.i-25 {
  width: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.customIcon {
  transform: translate(100%, 50%);
  color: rgb(1, 101, 144);
  position: absolute;
}

#estudio {
  background-color: $redColor;
  border-color: $redColor;
  color: white;
}

#findUserName {
  display: flex;
}

#filterServices {
  max-width: 400px;
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .separador-table-result{
//     border-top: 3px solid #99CA3E;
//     margin: 10px 0px;
// }

// .separador-result{
//     border-top: 3px solid rgb(171 195 219);
//     margin-bottom: 10px;
// }

.col-years {
  display: flex;
  justify-content: end;
}

#paginate {
  display: flex;
  justify-content: end;
}

// .number-paginate{
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .number-paginate a{
//     color:#033261 !important;
// }

// .section_pshb_titulo{
//     color: #05294F;
//     font-family: "Roboto-Medium";
//     font-size: 30px;
// }

// .section_pshb_subtitulo{
//     color: #05294F;
//     font-family: "Roboto-Regular";
//     font-size: 20px;
// }

.information-circle {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.information-triangle {
  width: 0;
  height: 0;
  border-left: 25px solid #f0ad4e;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  // transform: translateX(-30px);
}

#header-invoice {
  display: flex;
  justify-content: space-between;
}

#icon-information {
  height: 40px;
  width: auto;
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
}

.checkbox-container label {
  margin-left: 5px;
}

.checkboxInput {
  margin-bottom: 69px;
}

#myCheckbox {
  margin-top: 3px !important;
}
