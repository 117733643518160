@use "../../../assets/style/colors" as color;

.input-login-v22 {
  border-radius: 8px !important;
  padding: 8px !important;
  width: 25% !important;
}

.div-login2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px 120px 0px 120px;
}

.texto-desactivar {
  padding: 16px 0px 16px;
  margin: 0;
  max-width: 1000px;
}

@media only screen and (max-width: 800px) {
  .input-login-v22 {
    width: 70% !important;
  }

  .div-login2 {
    padding: 20px 30px 20px 30px;
    width: 100vw;
    height: 100%;
  }

  .texto-desactivar {
    padding: 16px 0px 0px;
  }
}
