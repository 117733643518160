.iconosAtencion {
  margin-left: 5px;
  margin-right: 5px;
}

.scrollable-aside {
  // max-height: 100%;
  // overflow-y: auto;
  // height: calc(100vh - 108px);
}

.asideWebHB {
  //min-height: 100vh;
  box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  width: 18vw; //16vw ;
  min-width: 18vw;
  //   background: rgb(2, 54, 107);
  background: #efefef;
  display: flex;
  padding-top: 30px;
  font-family: Roboto-Regular;
  max-width: 220px;
  z-index: 1;
}
.asideWebPSHB {
  //min-height: 100vh;
  width: 18vw;
  min-width: 18vw;
  //background: linear-gradient(0deg, #05294F 20%, #02366B 40%, #034F9A 60%, #0062C1 80%, #3CCEEB 100%);
  background: rgb(2, 54, 107);
  background: linear-gradient(
    360deg,
    rgba(2, 54, 107, 1) 0%,
    rgba(3, 79, 154, 1) 40%,
    rgba(60, 206, 235, 1) 100%
  ); //linear-gradient(0deg, #05294F 20%, #02366B 40%, #034F9A 60%, #0062C1 80%, #3CCEEB 100%);
  display: flex;
  padding-top: 40px;
  font-family: Roboto-Regular;
  max-width: 220px;
  padding-left: 10px;
  padding-right: 10px;
}

.asideWebPSHB .navigate-button {
  padding: 7px 0px 5px 0px;
}

.asideWebHB .navigate-button {
  // padding: 4px 0px 7px 0px;
  //   width: "100%";
  line-height: 130% !important;
  text-align: start !important;
  font-size: 20px;
}

.asideMobile {
  display: none !important;
}

.separador {
  border-top: 1px solid #c5d8eb;
  min-height: 1px;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: center;
}

.separadorItem {
  border-top: 1px solid #cecccc;
  min-height: 1px;
  width: 95%;
  //   margin-top: 20px;
  //   margin-bottom: 10px;
  align-self: center;
}

.separadorTitulo {
  border-top: 2px solid #224a8d;
  min-height: 1px;
  // width: 50%;
  // margin-top: 15px;
  //   margin-bottom: 10px;
  align-self: center;
  box-shadow: 0px 2px 2px rgb(187, 182, 182);
}

.textoMenuLateral {
  font-size: 15px !important;
  width: 100%;
}

.circleSmall {
  display: none !important;
}

.navigate-button {
  //   color: inherit;
  border: none;
  padding: 15px 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #05294f;
  //   margin-top: 20px;
  // margin: 10px 10px 0;
  //font-weight: bold;
  //font-family: Roboto-Bold ;
}

.navigate-button:hover {
  color: #05294f;
  background-color: #e9e9e9;
}

// .navigate-button:hover {
//   color: rgb(209, 209, 209);
// }

.active {
  color: #02366b;
  font-family: Roboto-Medium;
}

@media only screen and (max-width: 1000px) {
  .asideWebHB {
    display: none !important;
  }
  .asideWebPSHB {
    display: none !important;
  }

  .asideMobile {
    display: block !important;
    width: 10vw;
    background: linear-gradient(
      0deg,
      #05294f 20%,
      #02366b 40%,
      #034f9a 60%,
      #0062c1 80%,
      #3cceeb 100%
    );
    position: relative;
    // min-height: 100vh;
  }

  .showAsideMobile {
    width: 50vw;
    background: linear-gradient(
      0deg,
      #05294f 20%,
      #02366b 40%,
      #034f9a 60%,
      #0062c1 80%,
      #3cceeb 100%
    );
    position: absolute;
    z-index: 1000;
    // min-height: 100vh;
  }
}
