@use "../../../assets/style/hb/variables" as v;

#container-ayuda {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: right;
  width: 100%;
}

#caja-ayuda {
  font-family: Roboto-Regular;
  color: #05294f;
}

.titulo-ayuda-ps {
  font-family: Roboto-Medium;
  color: #05294f;
}

.subtexto-ayuda-ps {
  color: #05294f;
  font-size: 19px;
}

.strong {
  color: #8acb48;
}

.accordion-text {
  font-family: Roboto-Regular;
  color: #05294f;
  font-size: 18px;
}

.accordion-body {
  padding-left: 4.5rem !important;
  padding-right: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 1rem !important;
}

.accordion-header {
  height: 80px;
  display: flex;
}

.accordion-button {
  width: 100%;
  border-radius: 20px;
  font-size: 16px;
  font-weight: normal;
  color: #05294f;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordion-item:first-of-type {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.accordion-item {
  border: none;
  box-shadow: 0px 0px 16px 0px rgba(35, 131, 252, 0.24);
  border-radius: 20px;
}
//Green Arrow

// .accordion-button:not(.collapsed)::after {
//     background-image: url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAVCAYAAADB5CeuAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzklEQVRIS73WbQ6CMAyA4cFR9DrKXdXzeBblXTLixyhtt+75ISRG+lohcXqt0up8v3JIz+WRj6PU5s68lDfweR5tb+50ul3ypn5Fb2zvyzM3b6omcmNH156ljUSESdcsLXlTo8I0Qdh+vugwbRC+7qmoMEsQ/m703mHWIFSfvl5hniBUo9Aa5g3CbhS8YS1BEKNgDWsNwmEUtGE9grD9S9CQhkosQVBtqrBeHJ7PmKJgGeIJgjkKmmHeILiiIA1tCYI7CrXhrUEwPX1jpPQGAuBxjys047oAAAAASUVORK5CYII=);
//     transform: rotate(-180deg);
// }

// .accordion-button::after {
//     background-image: url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAVCAYAAADB5CeuAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzklEQVRIS73WbQ6CMAyA4cFR9DrKXdXzeBblXTLixyhtt+75ISRG+lohcXqt0up8v3JIz+WRj6PU5s68lDfweR5tb+50ul3ypn5Fb2zvyzM3b6omcmNH156ljUSESdcsLXlTo8I0Qdh+vugwbRC+7qmoMEsQ/m703mHWIFSfvl5hniBUo9Aa5g3CbhS8YS1BEKNgDWsNwmEUtGE9grD9S9CQhkosQVBtqrBeHJ7PmKJgGeIJgjkKmmHeILiiIA1tCYI7CrXhrUEwPX1jpPQGAuBxjys047oAAAAASUVORK5CYII=) ;
//     background-position: calc(100% - 0px) 5px, calc(100% - 20px) 15px, 100% 0
// }

// .accordion-button::after {
//     background-image: url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAVCAYAAADB5CeuAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzklEQVRIS73WbQ6CMAyA4cFR9DrKXdXzeBblXTLixyhtt+75ISRG+lohcXqt0up8v3JIz+WRj6PU5s68lDfweR5tb+50ul3ypn5Fb2zvyzM3b6omcmNH156ljUSESdcsLXlTo8I0Qdh+vugwbRC+7qmoMEsQ/m703mHWIFSfvl5hniBUo9Aa5g3CbhS8YS1BEKNgDWsNwmEUtGE9grD9S9CQhkosQVBtqrBeHJ7PmKJgGeIJgjkKmmHeILiiIA1tCYI7CrXhrUEwPX1jpPQGAuBxjys047oAAAAASUVORK5CYII=) ;
//     background-position: calc(100% - 0px) 5px, calc(100% - 20px) 15px, 100% 0;
// }

.separador-ps {
  height: 2px;
  border: 2px solid v.$success;
  margin-bottom: 20px;
}

.button-accordion {
  color: v.$success;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed) {
  color: #05294f;
}
// @media only screen and (max-width:475px)
// {
//   .accordion-button{
//     font-size: 1rem !important;
//   }
// }

// @media only screen and (max-width:361px)
// {
//   .accordion-button{
//     font-size: 0.9rem !important;
//   }
// }

#caja-ayuda {
  height: 100%;
}

@media (max-width: 992px) {
  .container-help {
    margin: 0px !important;
  }

  // .titulo-ayuda-ps{
  //   font-size:30px;
  // }
}
