.accordion-header {
  height: 80px;
  display: flex;
}

.titulo-ayuda-hb {
  font-family: Roboto-Medium;
  color: #05294f;
  font-size: 25px !important;
}

#caja-ayuda-hb {
  font-family: Roboto-Medium;
  color: #05294f;
}

.body-accordion-ayuda-hb {
  font-family: Roboto-Medium;
  color: #05294f;
  padding: 0px 1rem 0px 1rem;
  font-size: 1.2rem;
}

.accordion {
  font-family: Roboto-Medium;
}

.accordion-text-hb {
  font-family: Roboto-Regular;
  color: #05294f;
  font-size: 16px;
}

.ayuda-max-width {
  max-width: 75vw;
}

.separador-hb {
  border-top: 3px solid red;
  margin: 10px 0px;
}

.accordion-button {
  width: 100%;
  border-radius: 20px;
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: Roboto-Medium;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordion-item:first-of-type {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.accordion-item {
  border: none;
  box-shadow: 0px 0px 16px 0px rgba(47, 104, 248, 0.14);
  border-radius: 20px;
}

//Red Arrow
.accordion-button:not(.collapsed)::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAVCAYAAADB5CeuAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzElEQVRIS73WYQ6CMAxA4Y3b6WW8il7G6yFvyQjqVtpu5fshJEb6rJCY103a5PuLQ1rfj3K8Smvuwkt9A8fzaL25Od2eZVO/ojfW+/LMLZtqidzY2bUXaSMRYdI1a0vZ1FVhmiDsP190mDYIX/dUVJglCH83+uwwaxCaT9+sME8QmlEYDfMGoRsFb9hIEMQoWMNGg3AaBW3YjCDs/xI0pKESSxBUm6qsF4fnM6YoWIZ4gmCOgmaYNwiuKEhDR4LgjkJr+GgQTE/fNVL6AHu9cqcYxIxNAAAAAElFTkSuQmCC);
  transform: rotate(-180deg);
}

.accordion-button::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAVCAYAAADB5CeuAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAzElEQVRIS73WYQ6CMAxA4Y3b6WW8il7G6yFvyQjqVtpu5fshJEb6rJCY103a5PuLQ1rfj3K8Smvuwkt9A8fzaL25Od2eZVO/ojfW+/LMLZtqidzY2bUXaSMRYdI1a0vZ1FVhmiDsP190mDYIX/dUVJglCH83+uwwaxCaT9+sME8QmlEYDfMGoRsFb9hIEMQoWMNGg3AaBW3YjCDs/xI0pKESSxBUm6qsF4fnM6YoWIZ4gmCOgmaYNwiuKEhDR4LgjkJr+GgQTE/fNVL6AHu9cqcYxIxNAAAAAElFTkSuQmCC) !important;
  background-position: calc(100% - 0px) 5px, calc(100% - 20px) 15px, 100% 0;
}

@media only screen and (max-width: 1000px) {
  .ayuda-max-width {
    max-width: 100vw;
    padding-top: 0 !important;
  }
}

// @media only screen and (max-width:475px)
// {
//   .accordion-button{
//     font-size: 1rem !important;
//   }
// }

// @media only screen and (max-width:361px)
// {
//   .accordion-button{
//     font-size: 0.9rem !important;
//   }
// }

#caja-ayuda-hb {
  height: 100%;
}
