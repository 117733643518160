#h-logout-custom {
  height: calc(100vh - 86px - 33px);
}

#separator-blue-logout {
  width: 150px;
  border-top: 2px solid #05294f;
  margin: 10px 0px;
}

#title-logout-HB {
  color: #224A8D;
  font-family: "Roboto-Medium";
  font-size: 35px;
}

.btn-volver-hb
{
  padding: 5px 15px;
  width: fit-content;
}
// @media (max-width: 600px){

//   .h-logout-custom{
//     margin-top: 20px;
//   }

//   .logout-footer-pshb{
//     display: none;
//   }

// }

.boton-inicio-HB {
  color: #fff !important;
  // background-color: #05294F !important;
  background: linear-gradient(
    0deg,
    #0062c1 40%,
    #034f9a 60%,
    #02366b 80%,
    #05294f 100%
  );
  border-radius: 20px !important;
  padding: 3px 25px 3px 25px !important;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 35px;
  font-family: "Roboto-Medium";
  // font-size: 28px;
  border: 1px solid #0367c3 !important;
  &:hover {
    background-color: white !important;
    //   color:#033261 !important;
  }
}

#logout-footer-hb {
  background-image: url("../../../assets/img/hb/Backgrounds/bg-red-2.png");
  height: 100px;
  width: 100%;
}
