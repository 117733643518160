@media (max-width: 600px) {
  .img-contacto-mobile {
    display: none;
  }
}

.img-contacto {
  // background-image: url("../../../assets/img/hb/bg-contacto.jpg");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  // height: 100vh;
  height: 732px;
  // width: 50vw;
  // object-fit: cover;
}

.title-hb {
  color: #05294f;
  font-family: "Roboto-Medium";
  font-weight: normal !important;
  font-size: 25px !important;
}

.form-control {
  font-family: "Roboto-Regular";
  color: #05294f;
  font-size: 16px;
  font-weight: normal;
}

.medios-contacto {
  font-size: 25px;
  font-family: "Roboto-Medium";
  font-weight: normal !important;
}

.input-title-hb {
  font-family: "Roboto-Regular";
  font-size: 16px;
  font-weight: normal;
  color: #05294f;
}

.info-contacto {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: normal;
  color: #05294f;
}

.info-contacto a {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: normal;
  color: #05294f;
}

#politicas-hb {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: normal;
  color: #05294f;
}

#politicas-ps {
  font-family: "Roboto-Regular";
  font-size: 17px;
  font-weight: 600;
  color: #05294f;
}

#politicas-hb-2 {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: normal;
  color: #05294f;
}

.boton-contacto-hb {
  font-family: "Roboto-Regular";
  border: 0;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 50px;
  margin-top: 25px;
  margin-right: 10px;
  border-radius: 50px;
  transition: 0.7s;
  color: white;
  background: #05294f;
  text-decoration: none;
  height: 35px;

  &:hover {
    background: white;
    color: #05294f;
    border: 1px solid #5b78aa;
    border-radius: 20px;
    letter-spacing: 1px;
  }
}

.columna-contenido-sugerencias {
  //  padding: 0rem 3rem 0rem 3rem;
}
