.input-editing {
  background: #034787;
  border: none;
  color: white;
  outline: none;
}

.form-HB {
  font-family: Roboto-Medium;
}

.form-PSHB {
  font-family: Roboto-Medium;
}

.campo-perfil-email {
  display: grid;
  grid-template-columns: 1fr; //45px 1fr;
}

@media (max-width: 1290px) {
  .campo-perfil-email {
    grid-template-columns: 1fr;
  }
}

.underlined-input {
  border: none;
  border-bottom: 1px solid black; /* Puedes cambiar el color y el grosor del subrayado aquí */
  outline: none; /* Elimina el contorno predeterminado del input al hacer clic en él */
  color:rgb(3, 50, 97)
}