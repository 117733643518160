.App {
  font-family: cursive;
}

.title {
  text-align: left;
}

.custom-select-container {
  display: inline-block;
  // min-width: 250px;
  text-align: left;
  position: absolute;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: solid 2px transparent;
  font-family: "Roboto-Regular";
}

.custom-select-container-active {
  display: inline-block;
  // min-width: 250px;
  text-align: left;
  position: absolute;
  background-color: red;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 10;
  font-family: "Roboto-Regular";
}

.selected-text {
  background-color: red;
  padding: 4px 20px;
  // border-bottom: 1px solid #37b0b0;
  border-radius: 20px;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selected-text::after {
  // content: "﹀"; 
  // transform:rotate(90deg);
  width: 5px;
  height: 5px;
  position: absolute;
  right: 20px;
  // top: 10px;
  font-weight: bold;
  //border: 7px solid transparent;
  //border-color: #fff transparent transparent transparent;
}


.selected-text.active-item::after {
  top: 8px;
  border-color: transparent transparent #fff transparent;
}

.select-options {
  margin: 0;
  padding: 0;
  text-align: left;
}

.select-options {
  // position: absolute;
  width: 100%;
  border-radius: 20px;
}

// .select-options::-webkit-scrollbar {
//   width: 8px;               /* width of the entire scrollbar */
// }

// .select-options::-webkit-scrollbar-track {
//   background: transparent;        /* color of the tracking area */
// }

// .select-options::-webkit-scrollbar-thumb {
//   background-color: red;    /* color of the scroll thumb */
//   border-radius: 20px;       /* roundness of the scroll thumb */
//   border: 3px solid red;  /* creates padding around scroll thumb */
// }

.select-options li {
  list-style-type: none;
  padding: 6px 20px;
  background: red;
  color: white;
  // border-bottom: 1px solid #37b0b0;
  cursor: pointer;
}

.select-options li:hover {
  background-color: rgba($color: white, $alpha: 0.3);
  color: #ffffff;
}

.custom-select-option {
  border-radius: 20px;
}

.split {
  border-top: 1px solid white;
  margin: 0px 8px;
  margin-bottom: 2px;
}

.custom-select-container-red {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: solid 1px red;
}

.custom-select-container-red .active-item {
  margin-top: 3px;
}

.custom-select-container-red .custom-select-option {
  border-radius: 20px;
  background-color: white;
  color: red;
}

.custom-select-container-red .selected-text {
  background-color: white;
  color: red;
}

.custom-select-container-red li:hover {
  background-color: red;
  color: #ffffff;
}

.custom-select-container-red .split {
  border-top: 1px solid red;
}

.custom-select-container-blue {
  background-color: rgb(3, 50, 97);
}

.custom-select-container-blue .custom-select-option {
  border-radius: 20px;
  background-color: rgb(3, 50, 97);
}

.custom-select-container-blue .selected-text {
  background-color: rgb(3, 50, 97);
}

.custom-select-container-blue li:hover {
  background-color: rgba($color: white, $alpha: 0.3);
  color: #ffffff;
}

.custom-select-container-white {
  background-color: white;
  border: solid 1px #05294f;
}

.custom-select-container-white .custom-select-option {
  border-radius: 20px;
  background-color: white;
  color: rgb(3, 50, 97);
}

.custom-select-container-white .selected-text {
  background-color: transparent;
  color: rgb(3, 50, 97);
}

.custom-select-container-white li:hover {
  background-color: rgb(3, 50, 97);
  color: white;
}

.custom-select-container-white .split {
  border-top: 1px solid rgb(3, 50, 97);
  margin: 0px 8px;
  margin-bottom: 2px;
}

.custom-select-container-blue .selected-text::after{
  content: url("../../../assets/img/card-icons/Chevron.svg");  
  filter: invert(1);
}

.custom-select-container-red .selected-text::after{
  content: url("../../../assets/img/card-icons/Chevron.svg");  
  filter: invert(1);
}

.custom-select-container-white .selected-text::after {
  // content: "﹀";  
  content: url("../../../assets/img/card-icons/Chevron.svg");
  //filter: brightness(0) invert(1) !important;
  // transform:rotate(90deg);
  position: absolute;
  right: 20px;
  // top: 10px;
  font-weight: bold;
  // content: "";
  // position: absolute;
  // right: 10px;
  // top: 16px;
  // border: 7px solid transparent;
  // border-color: rgb(3, 50, 97) transparent transparent transparent;
}

.custom-select-container-white .selected-text.active-item::after {
  top: 8px;
  border-color: transparent transparent rgb(3, 50, 97) transparent;
}

// Profile
.custom-select-container-profile {
  background-color: rgb(3, 50, 97);
  border: none;
}

.custom-select-container-profile .custom-select-option {
  // border-radius: 20px;
  background-color: rgb(3, 50, 97);
}

.custom-select-container-profile .selected-text {
  background-color: #034787;
  border-radius: 0;
}

.custom-select-container-profile li:hover {
  background-color: rgba($color: white, $alpha: 0.3);
  color: #ffffff;
}

.selected-text-red {
  background-color: red !important;
  color: white !important;
}

.border-error {
  border-color: red !important;
}
