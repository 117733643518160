.header-proteccion {
  border-bottom: 0px !important;
  padding-right: 35px !important;
  padding: 0px !important;
  padding-left: 20px !important;
  padding-right: 30px !important;
  padding-top: 5px !important;
}

.body-proteccion {
  padding: 0px !important;
}

.titulo-facturas {
  color: #05294f;
  padding-left: 20px;
  padding-top: 60px;
  font-family: "Roboto-Medium";
  font-weight: normal !important;
  font-size: 25px;
  // font-weight: 700;
}

.preguntas-credencial {
  display: flex;
}

.preguntas-credencial img
{
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.answer-credencial {
  padding-bottom: 1em;
  padding-left: 2em;
  font-family: "Roboto-Regular";
  color: #05294f;
  font-weight: normal;
}

// .politica-proteccion{
//     color:#05294F;
//     padding: 0px 20px;

// }

// .politica-proteccion p{
//     text-align: justify;
//     font-family: Roboto-Regular;

// }

// .politica-proteccion h5{
//     font-family: Roboto-Regular;
//     color: #004990;
//     font-weight: 600;
// }

.section-invoice-information {
  display: flex;
  margin-right: 5px;
  flex-direction: row;
}

.image-information {
  padding-right: 10px;
}

.section-consult {
  margin-left: 10px;
}

.answer {
  font-family: "Roboto-Regular";
  color: #05294f;
  font-weight: normal;
}

.question {
  font-family: "Roboto-Medium";
  color: #05294f;
  font-weight: normal;
}

.flex-icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.span.subtitulo {
  font-size: 30px;
}

.subtitulo-credencial {
  font-size: 16px;
  text-align: justify;
  font-family: Roboto-Regular;
  color: #05294f;
  padding-left: 20px;
}

.texto-apps {
  font-weight: 600;
  padding-top: 30px;
}

.subtexto-apps {
  font: 400;
  font-size: 17px;
}

.iconos-apps {
  margin-bottom: 20px;
}

.tutorial-credencial-uno {
  height: 100%;
  width: 100%;
}

.tutorial-credencial-dos {
  height: 100%;
  width: 90%;
}

.icono-tienda {
  height: 36px;
  width: 110px;
  bottom: 0;
  margin-right: 10px;
  margin-top: 7px;
  border-radius: 10px;
}
