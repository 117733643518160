.grupoMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.grupoMenuItem {
  padding: 20px 5px 20px 5px;
  border-radius: 40px;
  text-align: center;
  // background: rgb(5,41,79);
  // background:linear-gradient(0deg, #05294F 0%, #004990 40%, #3FB2C9 100%); //linear-gradient(0deg, rgba(5,41,79,1) 0%, rgba(7,85,161,1) 27%, rgba(85,204,227,1) 100%);
  background: #ffffff;
  //   background: linear-gradient(
  //     360deg,
  //     rgba(5, 41, 79, 1) 0%,
  //     rgba(0, 73, 144, 1) 22%,
  //     rgba(63, 178, 201, 1) 100%
  //   );
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 85px;
  margin: 10px 20px 0px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px;
}

.grupoMenuIcono {
  height: 38px;
  // height: 3em;
  margin-right: 15px;
}

.grupoMenuTexto {
  color: #05294f;
  font-size: 26px;
  // font-size: 1em;
  line-height: 28px;
  margin-right: 0px;
  //margin-right:15px; --> Facturas
  font-family: Roboto-Regular;
}

.icono-atencion {
  padding-left: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .grupoMenuTexto {
    font-size: 15px;
  }

  .icono-atencion {
    padding-left: 10px;
  }

  .grupoMenuIcono {
    margin-right: 8px;
  }
}

@media (max-width: 768px) {
  .grupoMenuTexto {
    font-size: 15px;
    line-height: 18px;
    font-family: "Roboto-Medium";
  }

  .icono-atencion {
    padding-left: 10px;
  }

  .grupoMenuIcono {
    margin-right: 8px;
    height: 24px;
  }

  .grupoMenuItem {
    padding: 20px 5px 20px 5px;
    height: 50px;
    margin: 10px 20px 0px 20px;
  }
}

@media (max-width: 1400px) {
  .grupoMenuItem {
    margin: 10px 20px 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
  }
}

a {
  text-decoration: none;
}

.icononoNovedades {
  margin-bottom: 3px;
  padding-left: 5px;
}

.itemDown {
  display: flex;
  justify-content: end;
  margin-bottom: -18px;
  width: 230px;
}

.itemDownHB {
  display: flex !important;
  justify-content: end !important;
  margin-bottom: -18px;
}
