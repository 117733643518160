.table-pshb thead tr {
  font-family: "Roboto-Regular";
  font-size: 20px;
  color: #05294f;
  text-align: justify;
}

.table-pshb tbody tr {
  font-family: "Roboto-Regular";
  font-size: 18px;
  color: #05294f;
  text-align: justify;
}

.btn-pshb {
  &-primary {
    color: #fff !important;
    background-color: #8acb48 !important;
    border-radius: 20px !important;
    padding: 3px 20px 3px 20px !important;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    height: 35px;
    font-family: "Roboto-Bold";
    font-size: 35px;
    border: 2px solid #8acb48 !important;
    &:hover {
      background-color: white !important;
      color: #033261 !important;
    }
  }

  &-primary-without-border {
    color: #fff !important;
    background-color: #05294f !important;
    border-radius: 20px !important;
    padding: 3px 20px 3px 20px !important;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    height: 35px;
    font-family: "Roboto-Medium";
    font-size: 28px;
    border: 1px solid #0367c3 !important;
    &:hover {
      background-color: white !important;
      color: #033261 !important;
    }
  }

  &-secondary-without-border {
    color: #033261 !important;
    background-color: #fff !important;
    // border:1px solid #033261 !important;
    border-radius: 20px !important;
    padding: 3px 20px 3px 20px !important;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    height: 35px;
    font-family: "Roboto-Medium";
    &:hover {
      color: #fff !important;
      background-color: #05294f !important;
    }
  }
}

.footer-pshb {
  background-image: url("../../assets/img/pshb/Backgrounds/bg-green.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  height: 100px;
  width: 100%;
}

.footer-pshb-large {
  background-image: url("../../assets/img/pshb/Backgrounds/FooterVerde.svg");
  height: 90px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

//   .green-footer{
//     background-image: url("../../assets/img/pshb/Backgrounds/bg-green.png");
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position-y: bottom;
//     height: 100px;
//     width: 100%;
// }

.number-pshb-paginate {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto-Bold";
}

.number-pshb-paginate a {
  color: #004990;
}

.separador-table-green-result {
  border-top: 2px solid red;
  margin: 10px 0px;
}

.separador-table-green-result-slim {
  border-top: 1px solid red;
  margin: 10px 0px;
}

.section_pshb_title {
  color: #05294f;
  font-family: "Roboto-Medium";
  font-size: 30px;
}

.section_pshb_subtitle {
  color: #05294f;
  font-family: "Roboto-Regular";
  font-size: 20px;
}

.section_pshb_subtitle_2 {
  color: #05294f;
  font-family: "Roboto-Regular";
  font-size: 15px;
}

.section_pshb_subtitle_3 {
  color: #05294f;
  font-family: "Roboto-Regular";
  font-size: 10px;
}

.header-modal-pshb {
  border-bottom: 0px !important;
  padding: 0.5rem 0.5rem !important;
}

.label-pshb {
  font-family: "Roboto-Regular";
  color: #05294f;
  font-size: 20px;
  margin-top: 3px;
}

.input-text-pshb {
  font-family: "Roboto-Medium";
  color: #05294f;
  font-size: 15px;
}

@media (max-width: 992px) {
  .footer-pshb-large {
    background-size: contain;
  }
}
