.perfil-PSHB .selected-text {
  font-family: Roboto-Medium;
}

.perfil-PSHB .custom-select-option {
  font-family: Roboto-Medium;
}

.boton-PSHB {
  font-family: Roboto-Medium;
}

.boton-HB {
  font-family: Roboto-Medium;
}

.contenedor-botones
{
  width: 100%;
  display: flex;
  justify-content:end;
  margin-top: 20px,
}

/* Extra small devices (telefonos, menos de 576px) */
@media (max-width: 575.98px) { 
  .contenedor-botones button
  {
    width: 7em;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 15px;    
  } 
}

.botonEditar-disable {
  background-color: rgb(138, 203, 72);
  border: 1px solid rgb(138, 203, 72);
  width: 8em;
  border-radius: 25px;
  color: #fff;
  margin-right: 10px;
  opacity: 0.6;
}