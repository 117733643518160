h2 {
  margin: 0;
  padding: 0;
}

.btn-hb-primary-without-border {
  font-family: Roboto-Regular !important;
}
.recupero-contrasena-card {
  width: 75vw;
  // height: 70vh;
  background-color: white;
  border-radius: 10px;
  // border-left: 20px solid red;
  padding: 30px;
  margin: 20px;
  box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
  -webkit-box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
  -moz-box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
}

.recupero-contrasena-cardbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.font-color-PSHB {
  font-weight: normal;
  color: #05294f;
  font-family: Roboto-Medium;
  font-size: 25px;
}
.font-color-HB {
  //font-weight: bold;
  color: #05294f;
  font-family: Roboto-Bold;
  font-size: 2rem;
}

.introducion-cambio-contraseña-HB {
  // font-size: 1.5rem;
  color: #05294f;
  font-family: Roboto-Medium;
  text-align: center;
}

.texto-cambio-contraseña-HB {
  // font-size: 1rem;
  padding-top: 2rem;
  color: #05294f;
  font-family: Roboto-Medium;
  text-align: center;
}

.texto-input-cambio-contraseña-HB {
  text-align: center;
  font-family: Roboto-Medium;
  font-size: 1.3rem;
  color: #05294f;
}

.recupero-contrasena-input {
  border-radius: 10px;
  width: 100%;
  // border: 2px solid red;
  min-height: 6vh;
  color: #05294f;
  padding-left: 2vh;
  padding-right: 2vh;
}

.recupero-contrasena-shadow-blue {
  -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.recupero-contrasena-input {
  border: 2px solid red;
  width: 100%;
  border-radius: 10px;
  min-height: 4.5vh;
  display: flex;
  align-items: center;
}

.recupero-contrasena-input i {
  // margin-left: 2vh;
}

.recupero-contrasena-input button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 99%;
  color: #05294f;
  font-weight: 500;
}

.button-continuar-recuperar {
  min-width: 150px;
  width: 150px;
  font-family: Roboto-Regular !important;
}

.recupero-contrasena-card-PSHB .titulo {
  font-family: "Roboto-Medium" !important;
}

.recupero-contrasena-card-PSHB .subtitulo-cambio {
  font-family: "Roboto-Regular" !important;
}

.recupero-contrasena-card-PSHB .email-cambiar {
  font-family: "Roboto-Regular" !important;
}

.recupero-contrasena-card-HB .titulo {
  font-family: "Roboto-Medium" !important;
  font-weight: normal;
}

.recupero-contrasena-card-HB .subtitulo-cambio {
  font-family: "Roboto-Regular" !important;
  font-size: 16px !important;
}

.recupero-contrasena-card-HB .email-cambiar {
  font-family: "Roboto-Regular" !important;
}

#contenedor-cambio-contrasena {
  margin-left: 5vw;
  margin-right: 10vw;
  // margin-top: -30px;
}

@media (max-width: 992px) {
  #contenedor-cambio-contrasena {
    margin-left: 0px;
    margin-right: 0px;
  }
  .recupero-contrasena-card {
    margin: 20px 0px 0px 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .recupero-contrasena-card {
    width: 90vw;
    // height: 50vh;
  }
}

@media only screen and (max-width: 550px) {
  .recupero-contrasena-card {
    width: 90vw;
    // height: 55vh;
  }

  .recupero-contrasena-card i {
    display: none;
  }
  .recupero-contrasena-input {
    padding-left: 0vh;
    padding-right: 0vh;
  }
}
