.integrante-pshb
{
    color:rgb(3, 50, 97);
}

.integrante-hb
{
    color:rgb(3, 50, 97);
}

.opciones-card-grupo-familiar
{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap:10px;
}

.opciones-card-grupo-familiar img
{
    cursor: pointer;
}

.integrante-hb .perfil-etiqueta
{
    font-family: Roboto-Medium;
}

.integrante-hb .perfil-valor
{
    font-family: Roboto-Regular;
    font-weight: normal;
}