.container {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
  width: 100%;
}

.card-refund {
  padding: 25px;
  // min-width: 70vw !important;
}

@media only screen and (max-width: 500px) {
  .card-refund {
    // padding:25px;
    min-width: 70vw !important;
    margin: 0 !important;
  }
}

.section-date {
  display: flex;
  flex-direction: column;
}

.sections-date {
  display: flex;
  flex-direction: row;
}

.section-search-and-print {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-icons-pshb i {
  position: absolute;
}

.input-icons-pshb {
  margin-bottom: 10px;
}

.input-icons-pshb .icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field-results-pshb {
  padding: 2px 35px;
  border-radius: 20px;
  border: 2px solid transparent;
  outline: none;
  background-color: white;
  color: #05294f;
  font-family: "Roboto-Regular";
  margin-top: 1px;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #05294f;
    opacity: 1; /* Firefox */
    font-family: "Roboto-Regular";
  }
}

.custom-icon-results-pshb {
  transform: translate(100%, 50%);
  color: #8acb48;
  position: absolute;
}
.input-icons-date-pshb i {
  position: absolute;
}

.input-icons-date-pshb {
  margin-bottom: 10px;
  display: flex;
  justify-content: end;
}

.input-icons-date-pshb .icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field-results-date-pshb {
  padding: 2px 35px;
  border-radius: 20px;
  border: 2px solid transparent;
  outline: none;
  background-color: white;
  color: #05294f;
  font-family: "Roboto-Regular";
  margin-top: 1px;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #05294f;
    opacity: 1; /* Firefox */
    font-family: "Roboto-Regular";
  }
}

.custom-icon-results-date-pshb {
  transform: translate(-120%, 40%);
  color: #05294f;
  fill: #05294f;
  position: absolute;
}

input[type="date"]:focus {
  color: #05294f;
}

// input[type="date"]::-webkit-inner-spin-button,
// input[type="date"]::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
// }

input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}

.header-digital-credential {
  //  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // padding-bottom: 30px;
}

.align-self-bottom {
  align-self: flex-end;
  align-items: center;
}

.integrante-grupo-familiar {
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  span {
    padding-left: 10px;
    font-family: "Roboto-Regular";
  }
}

#digital-credential-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;

  img {
    width: 45px;
    height: 45px;
    cursor: pointer;
  }

  span {
    font-size: 10px;
    color: #05294f;
    font-family: "Roboto-Regular";
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.userSelected {
  font-family: "Roboto-Medium" !important;
  color: #6286ac;
}

//.card-type{
// width:450px;
// height: 285px;
// background-position: center;
// background-repeat: no-repeat;
// background-size: cover;}

#card-premium {
  background-image: url("../../../assets/img/cards/premium.png");
}

#card-classic {
  background-image: url("../../../assets/img/cards/classic.png");
}

.card-number {
  color: white;
  top: 150px;
  position: relative;
  left: 30px;
  display: block;
  font-size: 20px;
  font-family: "Roboto-Light";
  letter-spacing: 2px;
}

.card-user-name {
  color: white;
  top: 155px;
  position: relative;
  left: 30px;
  display: block;
  font-family: "Roboto-Medium";
}

.card-separator {
  border-top: 1px solid white;
  top: 165px;
  position: relative;
  left: 30px;
  width: 70%;
}

.card-desde {
  top: 175px;
  position: relative;
  left: 30px;
  color: white;
  display: block;
  font-family: "Roboto-Regular";
}

.card-hasta {
  top: 151px;
  position: relative;
  left: 170px;
  color: white;
  display: block;
  font-family: "Roboto-Regular";
}

.fieldset {
  border-radius: 20px;
  min-width: inherit !important;
  border: 1px solid #05294f !important;
  padding-left: 10px;
  padding-right: 10px;

  legend {
    float: inherit !important;
    width: inherit !important;
    padding: inherit !important;
    margin-bottom: inherit !important;
    font-size: 12px;
    margin-left: 20px;
    color: #05294f;
    font-family: "Roboto-Regular";
  }

  input {
    outline: none;
    border: 0;
    width: 100%;
    margin-bottom: 5px;
    color: #2c68a3;
    font-family: "Roboto-Regular";
  }
}

.section_pshb_title_1 {
  color: #05294f;
  font-family: "Roboto-Medium";
  font-size: 30px;
  padding-bottom: 20px;
}

.section_pshb_title_2 {
  color: #05294f;
  font-family: "Roboto-Medium";
  font-size: 30px;
  padding-bottom: 20px;
}

.btn-pshb {
  font-family: "Roboto-Regular" !important;
  padding-top: 5px !important;
}

.option-register-credential {
  border-radius: 20px;
  width: 50%;
  border: 1px solid #224d7c;
  min-height: 4.5vh;
  color: #224d7c;
  padding-left: 0vh;
  padding-right: 0vh;
  font-weight: bold;
  opacity: 0.7;
  color: #224d7c;
  background-color: white;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.option-register-credential:focus {
  outline: none !important;
  border-color: #224d7c;
  color: #6286ac;
  padding-left: 0vh;
}

/* Chrome, Safari, Edge, Opera */

@media only screen and (max-width: 768px) {
  .header-digital-credential-title {
    margin-top: 50px !important;
  }

  .text-credencial-digital
  {
    margin-top:1rem;
  }
}

@media only screen and (min-width: 1000px) {
  .section_pshb_title_1 {
    white-space: nowrap;
  }
}

@media (max-width: 992px) {
  .padding-digital-credential {
    padding: 0px !important;
  }

  .row-digital-credential {
    width: 100%;
  }

  .card-type img {
    width: 100%;
  }
}

.share-container-credential
{
  display: flex;
  gap: 10px;
}

.tooltip-help-credential{
  display: flex;
  align-items: end;
}
