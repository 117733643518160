@font-face {
  font-family: "Roboto-Black";
  src: url("./assets/fonts/Roboto-Black.ttf");
}

@font-face {
  font-family: "Roboto-BlackItalic";
  src: url("./assets/fonts/Roboto-BlackItalic.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  src: url("./assets/fonts/Roboto-BoldItalic.ttf");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("./assets/fonts/Roboto-Italic.ttf");
}
@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: "Roboto-LightItalic";
  src: url("./assets/fonts/Roboto-LightItalic.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-MediumItalic";
  src: url("./assets/fonts/Roboto-MediumItalic.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Thin";
  src: url("./assets/fonts/Roboto-Thin.ttf");
}
@font-face {
  font-family: "Roboto-ThinItalic";
  src: url("./assets/fonts/Roboto-ThinItalic.ttf");
}
@font-face {
  font-family: "Roboto-Black";
  src: url("./assets/fonts/Roboto-Black.ttf");
}
//*******************************************
@font-face {
  font-family: "Assistant-SemiBold";
  src: url("./assets/fonts/Assistant-SemiBold.ttf");
}

@font-face {
  font-family: "Assistant-Regular";
  src: url("./assets/fonts/Assistant-Regular.ttf");
}

@font-face {
  font-family: "Assistant-Bold";
  src: url("./assets/fonts/Assistant-Bold.ttf");
}

@font-face {
  font-family: "Assistant-Light";
  src: url("./assets/fonts/Assistant-Light.ttf");
}

@font-face {
  font-family: "Assistant-Medium";
  src: url("./assets/fonts/Assistant-Medium.ttf");
}

@font-face {
  font-family: "Objectivity-Regular";
  src: url("./assets/fonts/Objectivity-Regular.otf");
}

@font-face {
  font-family: "Objectivity-Bold";
  src: url("./assets/fonts/Objectivity-Bold.otf");
}

@font-face {
  font-family: "Objectivity-Medium";
  src: url("./assets/fonts/Objectivity-Medium.otf");
}

@font-face {
  font-family: "Objectivity-Light";
  src: url("./assets/fonts/Objectivity-Light.otf");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width:1000px){
  .content-manager-site{
    padding-top: 80px !important;
  } 
}