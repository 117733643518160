#title-logout-pshb {
  font-family: "Roboto-Medium";
  font-size: 30px;
}

.h-logout-custom {
  // padding-top: 30vh;
  z-index: 1001;
  background-color: white;
}

.footer-logout {
  // border: 2px solid green;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.font-logout-pshb {
  font-family: Roboto-medium;
  font-size: 25px;
  color: #05294f;
}

// @media (max-width: 600px){

//   .h-logout-custom{
//     padding-top: 20px;
//   }

//   .logout-footer-pshb{
//     display: none;
//   }

// }

#separador-logout-pshb {
  width: 150px;
  border-top: 2px solid #8acb48;
  margin: 10px 0px;
}

.boton-inicio-PSHB {
  color: #fff !important;
  // background-color: #05294F !important;
  // background: linear-gradient(0deg, #3CCEEB 5%, #0062C1 20%, #034F9A 30%, #02366B 80%,#05294F  100%);
  background: linear-gradient(
    0deg,
    #0062c1 40%,
    #034f9a 60%,
    #02366b 80%,
    #05294f 100%
  );
  border-radius: 20px !important;
  padding: 3px 25px 3px 25px !important;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 35px;
  font-family: "Roboto-Medium";
  // font-size: 28px;
  border: 1px solid #0367c3 !important;
  &:hover {
    background-color: white !important;
    //   color:#033261 !important;
  }
}

#logout-footer-pshb {
  background-image: url("../../../assets/img/pshb/Backgrounds/bg-green.png");
  height: 100px;
  width: 100%;
}
