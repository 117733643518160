.grandientNavbarHB {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 33px;
  color: #fff;
  background: transparent
    linear-gradient(180deg, #05294f 0%, #004990 11%, #3fb2c9 100%, #6cb2f1 100%)
    0% 0% no-repeat padding-box;
  padding-left: 15px;
}

.grandientNavbar_elements {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grandientNavbar_searchInput {
  height: 28px;
  margin-right: 5px;
  margin-left: 5px;
}

.grandientNavbar_separator {
  border-right: 1px solid white;
  height: 28px;
  margin-left: 5px;
  margin-right: 5px;
}

.grandientNavbar_idioma {
  color: #ffffff;
  font-size: 12px;
  line-height: 2.3;
  align-items: center;
  display: flex;
}

.grandientNavbar_idioma_icon {
  height: 20px;
  margin-left: 5px;
}

/* Navbar */
.customNavbar {
  z-index: 2;
  padding-left: 24px;
  padding-right: 5px;
  box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.customNavbar_brand {
  display: flex;
}

.customNavbar_brand_logo {
  /* width: 7em; */
  width: 150px;
}

.customNavbar_brand_logo_separator {
  // border-right: 2px solid #b6b8bb;
  margin-left: 50px;
  margin-right: 10px;
  height: 52px;
}

.customNavbar_brand_button {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.customNavbar_brand_button_home {
  width: 24px;
}

.nav-mega {
  width: 100%;
}

a#basic-nav-dropdown {
  text-align: center;
}

/* .nav-mega .dropdown { 
    position: static; 
} */

/*nav-mega .dropdown .dropdown-menu.show {
    /* max-width: 1140px; */
/* min-width: auto;
    width: 100%;
    margin: auto;
    right: 0;
    padding: 0;
    border-color: #ccc;
    height: 361px; */

/* .nav-mega .dropdown .dropdown-menu.show li{
    padding: 20px;
}

.nav-mega .dropdown .media-list {
    padding: 10px; font-size: 13px;
    
} */

/* .nav-mega .dropdown-toggle:after{
    display:none
} */

/* .dropdown-menu.show:before {
    content: "";
    background: url('../../assets/img/onda-pacientes.png');
    background-repeat: no;
    width: 100%;
    height: 120px;
    position: absolute;
    top: 100%;
    
} */

/* .nav-mega .dropdown .dropdown-menu.show::before {
    border-radius: 100%;
    position: absolute;
    background: black;
    right: -200px;
    left: -200px;
    top: -200px;
    content: '';
    bottom: 0;
  } */

/* Normal Button */

.navItem__button {
  color: #05294f !important;
  font-family: "Assistant-VariableFont_wght";
  font-size: 16pt;
  margin: auto 0.8em auto;
}

.navItem__button:hover {
  transform: scale(1.2);
  transition: all 0.4s ease;
}

/* Button Plan de salud */

.btn.navItem__button--plandesalud {
  color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

.btn.navItem__button--plandesalud:hover {
  color: white;
  font-weight: 800;
  background-color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

/* Button HB Online */

.btn.navItem__button--hb {
  color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

.btn.navItem__button--hb:hover {
  color: white;
  background-color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

.btn.navItem__button--hb:hover .color {
  display: none;
}

.btn.navItem__button--hb .transparent {
  display: none;
}

.btn.navItem__button--hb:hover .transparent {
  display: initial;
}

.saludoUsuarioHB {
  color: #004990;
  font-size: 26px;
  font-weight: bolder;
  font-family: Roboto-Bold;
}

/* .contenedorOpcionMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
} */

.opcionMenu-1 {
  color: #224a8d !important;
  font-size: 15px !important;
  font-weight: 0;
  //padding-right: 45px;
  font-family: Roboto-Regular;
  // :hover{
  //     transform: scale(1.1);
  //}
}

.opcionMenuPlan {
  color: #224a8d;
  font-size: 15px;
  font-weight: 0;
  padding-right: 45px;
  font-family: Roboto-Regular;
  outline: 1px solid #004990;
  border-radius: 25px;
  padding: 5px 16px;
  margin-right: 18px;
}

.opcionMenuPlan:hover {
  background-color: #004990; /* Cambiar el fondo al hacer hover */
  color: #fff; /* Cambiar el color del texto al hacer hover */
}

.buttonUserName {
  background-color: #004990;
  color: #fff;
  font-size: 15px;
  font-weight: 0;
  padding-right: 45px;
  font-family: Roboto-Bold;
  outline: 1px solid #004990;
  border-radius: 25px;
  padding: 3px 16px;
  margin-right: 38px;
  height: 100%;
  cursor: pointer;
  margin-top: -1px;
}

.opcionMenu2 {
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  font-family: Roboto-Regular;
  //:hover{
  //   transform: scale(1.1);
  //}
}

.opcionMenuCerrarSesion {
  color: #ff0000;
  font-size: 20px;
  font-weight: 600;
  font-family: Roboto-Medium;
}

.logoPlanSaludMovil {
  background-color: #004990;
}

.logoPlanSaludMovil div {
  border: 0px;
  text-align: center;
  background-color: #004990;
}

.logoPlanSaludMovil div a {
  border-top: 1px solid #e1e9f2;
}

.itemMenuMovil a {
  border-top: 1px solid #e1e9f2;
  width: 95%;
  margin: 0px auto;
  text-align: center;
}

.mobile-navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  display: none;
  z-index: 2;
  box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);  
}

.nav-link {
  padding-right: 20px;
}

.planSaludMenu {
  z-index: 1;
  top: 108px !important;
  background-color: #224a8d !important;
  color: #fff !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}
.planSaludMenu span {
  color: #fff !important;
  margin-right: 45px;
}

@media only screen and (max-width: 1000px) {
  .web-navbar {
    display: none !important;
  }

  .mobile-navbar {
    display: block;
  }
}

.informacion-invitacion-pshb
{
  color:#05294f;
  font-family: "Roboto-Regular";  
  font-size: 16px;
  text-align: center;
}

.contenido-invitacion-pshb
{
  padding: 0rem 1rem 1rem 0rem;
  text-align: center;
  margin-top: -23px;
}

.dropdown-menu-userButton {  
  display: none;
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  // padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.dropdown-menu-userButton.show {
  display: block;
}

.dropdown-menu-userButton a{
  color: #004990;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  text-align: justify;
  padding-left: 15px !important;  
  padding-right: 15px !important;
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.dropdown-menu-userButton a:last-child{
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.dropdown-menu-userButton .dropdown-item:hover{
  background-color: #e9ecef;
}

#cerrar-sesion
{
  background-color:#004990;
  color:white;
  font-family: "Roboto-Regular";
  display: flex;
  justify-content: space-between;
}

#cerrar-sesion:hover{
  background-color:rgba(0, 73, 144, 0.8);
}

#button-info-user
{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}