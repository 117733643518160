.card-refund {
  padding: 25px;
}

.section-date {
  display: flex;
  flex-direction: column;
}

.sections-date {
  display: flex;
  flex-direction: row;
}

.section-search-and-print {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-icons-pshb i {
  position: absolute;
}

.input-icons-pshb {
  margin-bottom: 10px;
}

.input-icons-pshb .icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field-results-pshb {
  padding: 2px 35px;
  border-radius: 20px;
  border: 2px solid transparent;
  outline: none;
  background-color: white;
  color: #05294f;
  font-family: "Roboto-Regular";
  margin-top: 1px;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #05294f !important;
    opacity: 1; /* Firefox */
    font-family: "Roboto-Regular";
  }
}

.custom-icon-results-pshb {
  transform: translate(100%, 50%);
  color: #8acb48;
  position: absolute;
}

#anadir-cbu {
  width: 130px;
  position: absolute;
  margin-right: -10px;
  height: 31px;
}

.label-file {
  font-family: "Roboto-Medium";
  color: #05294f;
  font-size: 18px;
}
.error-label-pshb {
  color: red;
  font-family: "Roboto-Medium";
}

.h-20 {
  height: 20px;
}

.input-round {
  border-radius: 20px;
  width: 50%;
  border: solid 1px #05294f !important;
  // min-height: 4.5vh;
  //   height: 30.6px;
  color: #05294f !important;
  padding-left: 2vh;
  padding-right: 2vh;
  font-family: Roboto-Medium;
  font-size: 14px;
  padding-top: 3px;
}

.input-round::placeholder {
  opacity: 0.7;
  text-align: left;
  font-family: Roboto-Medium;
  color: #05294f !important;
  font-size: 90%;
  padding: 0px;
  //color: color.$color-HB-blue-strong;
}

.input-round:focus {
  outline: none !important;
  //border-color: color.$color-HB-blue-strong;
  //color: color.$color-HB-blue-light;
  padding-left: 2vh;
}

// input[type="date"]::-webkit-inner-spin-button,
// input[type="date"]::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
// }

.input-date-ios {
  -webkit-appearance: none;
}

.input-text-pshb {
  font-size: 90% !important;
}

.obligatorio {
  color: red;
}

.enfasis {
  color: #df5138;
}

#aclaracion-archivos {
  font-family: Roboto-Medium;
}

.tooltip-disc > .tooltip-inner {
  max-width: 400px;
  width: 100%;
}
