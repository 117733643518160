@use "../../assets/style/colors" as color;

// .signInDiv {
// width: 600px;
// background-color: red;
// padding-left: 10px;
// border-radius: 20px;
// }

.inputApple {
  border-radius: 20px;
  width: 100%;
  //border: 1px solid red;
  min-height: 4.5vh;
  // color: color.$color-HB-blue-strong;
  // padding-left: 1.5vh;
  // padding-right: 2vh;
  // padding-top: 0.6vh;
  background-color: black;
}

.inputApple::placeholder {
  font-weight: bold;
  opacity: 0.7;
  color: color.$color-HB-blue-strong;
}

.inputApple:focus {
  outline: none !important;
  border-color: color.$color-HB-blue-strong;
  color: color.$color-HB-blue-light;
  padding-left: 2vh;
}

@media only screen and (max-width: 1200px) {
  .inputApple {
    min-height: 6vh;
    width: 100%;
  }

  .appleAuthButton {
    min-height: 6vh;
  }
}

@media only screen and (max-width: 800px) {
  .inputApple {
    min-height: 6vh;
    width: 100%;
  }
  .appleAuthButton {
    min-height: 6vh;
  }
}

.appleAuthButton {
  font-family: "Google Sans", arial, sans-serif;
  background-color: black;
  border-radius: 20px;
  border: none;
  width: 100%;
  min-height: 4.5vh;
  color: white;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.appleAuthButton:focus {
  outline: none !important;
  border-color: color.$color-HB-blue-strong;
  border: none;
}
