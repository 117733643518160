.item-3-prescription {
  margin-left: auto;
}

.title_prescription {
  font-size: 23px !important;
}

.ico-row-recetas {
  font-size: 20px;
  margin-left: 8px;
  font-weight: bold;
}

.main-title-recetas {
  margin-top: 80px;
}

@media (max-width: 992px) {
  .main-title-recetas {
    margin-top: 25px !important;
  }
}
