.hb-style p {
  font-family: "Roboto-Medium" !important;
}

.hb-style h1 {
  font-family: "Roboto-Medium" !important;
}

.hb-style .button-continuar {
  font-family: "Roboto-Regular" !important;
}

.pshb-style .button-continuar {
  font-family: "Roboto-Regular" !important;
}
